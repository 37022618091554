.footer {
    background: #008080;
    color: #fff;
    padding: 20px 35px;

    .footer-content {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;
        margin-bottom: 20px;

        .footer-section {
            h4 {
                margin-bottom: 10px;
            }
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                li {
                    margin-bottom: 8px;
                }
            }
            a {
                color: #fff;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
            .social-links {
                display: flex;
                gap: 10px;
            }
        }
    }

    .footer-bottom {
        font-size: 14px;
        p {
            margin: 0;
        }
    }

    @media (max-width: 1024px) {
        .footer-content {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (max-width: 600px) {
        padding: 20px;
        .footer-content {
            grid-template-columns: 1fr;
        }
        .footer-section {
            margin-bottom: 20px;
        }
    }
}
