@import 'vars';
/* bebas-neue-regular - latin */
@font-face {
    font-display: swap;
    font-family: 'Bebas Neue';
    font-style: normal;

    font-weight: 400;
    src: url('../../public/fonts/bebas-neue-v14-latin-regular.woff2')
        format('woff2');
}
@font-face {
    font-family: 'Montserrat Variable';
    font-display: swap;
    src: '/static/media/montserrat-latin-wght-normal.b66f2d18f66f15c3ac56.woff2';
}
body {
    margin: 0;
    overflow-x: hidden;
    font-family: 'Montserrat Variable';
    -webkit-font-smoothing: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    background-color: #008080;
    li {
        font-weight: 500;
    }
    @media (min-width: 450px) {
        h1 {
            font-size: 2em;
            margin-top: 50px;
        }
    }
    h1,
    h2,
    h3,
    h4 {
        font-family: 'Bebas Neue', Arial, sans-serif;
        letter-spacing: 0.06em;
        font-weight: 400;
    }

    p {
        margin: 0 0 15px 0;
        letter-spacing: 0.06rem;
        line-height: 30px;
        font-size: 18px;
    }

    a {
        color: #000;

        &:hover {
            text-decoration: underline;
            color: #008080;
        }
    }
    .app {
        background-color: #fff;
    }

    .center {
        text-align: center;
    }
    .container {
        min-height: 80vh;
    }
    .container,
    .footer-container {
        max-width: $maxPageWidth;
        min-width: 320px;
        box-sizing: border-box;
        margin: 20px auto 0;
        @media (max-width: 840px) {
            margin: 0;
        }
    }
    .page-container,
    .footer-content {
        margin: 0 auto;
        padding: 0 15px; // a little space for nicer look
        @media (min-width: 750px) {
            padding: 0 35px; // a little space for nicer look
        }

        @media (min-width: 990px) {
            flex-basis: calc((100% - 120px) / 3);
        }

        @media (min-width: 1200px) {
            flex-basis: calc((100% - 155px) / 4);
        }

        @media (min-width: 1460px) {
            padding: 0;
        }
    }
    .page-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50vh;
        opacity: 0; /* Start with the element hidden */
        animation: fadeIn 1s ease-out 300ms forwards;
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .overlay {
        position: fixed;
        top: $header-height;
        left: 100vw;
        width: 100%;
        height: calc(100vh - #{$header-height});
        background-color: rgba(0, 0, 0, 0);
        z-index: 777;
        transition: background-color 300ms;
    }

    &.overlay-active {
        overflow: hidden;

        .overlay {
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
    .error {
        font-size: 14px;
        letter-spacing: 0.06rem;
        .icon {
            margin-right: 12px;
            vertical-align: middle;
        }
    }
    .spaced {
        letter-spacing: 0.06em;
    }

    .list-unstyled {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .fine-print {
        font-size: 13px;
        color: #808080;

        * {
            color: #808080;
        }
    }
    .text-input {
        outline: 1px solid #000;
        border-radius: 0;
        border: 0;
        padding: 15px;
        max-height: 23px;
        font-size: 20px;
        font-family: Arial, sans-serif;
        &:focus {
            outline-width: 2px;
        }
    }
    .button {
        border: 0;
        background-color: transparent;
        cursor: pointer;
        max-width: 360px;
        &.disabled {
            opacity: 0.3;
        }
    }
    .hidden {
        display: none;
    }
    .underline {
        text-decoration: underline;
        text-underline-offset: 3px;
    }
    .policy-page {
        font-family: Arial, sans-serif;
        line-height: 1.6;
        color: #333;

        padding: 00px;

        .container {
            max-width: 800px;
            margin: 20px auto;
            background-color: #fff;
            padding: 20px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            @media (max-width: 840px) {
                margin: 0;
            }
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: #444;
                margin-bottom: 10px;
            }

            p,
            li {
                font-size: 16px;
            }

            strong,
            b {
                font-weight: bold;
            }

            em,
            i {
                font-style: italic;
            }

            ul,
            ol {
                padding-left: 20px;
                margin-bottom: 20px;
            }

            a {
                color: #000;

                &:hover {
                    text-decoration: underline;
                }
            }

            blockquote {
                border-left: 5px solid #eee;
                padding-left: 15px;
                color: #666;
            }
        }
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
.chip {
    display: inline-flex;
    align-items: center;
    padding: 0.2rem 0.7rem;
    border-radius: 16px; /* Creates the rounded corners */
    background-color: #e0e0e0; /* Light grey background */
    color: #616161; /* Dark grey text */
    font-size: 0.875rem;
    line-height: 1.25rem;
    height: 31px; /* Standard chip height */
    box-sizing: border-box;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; /* Material-UI default font */
    cursor: default;
    user-select: none; /* Prevent text selection */
    transition: background-color 0.3s; /* Smooth background color transition on hover */
}

.chip:hover {
    background-color: #d5d5d5; /* Slightly darker grey on hover */
}

.icon-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    height: 44px;
    padding: 0 10px;
    box-sizing: border-box;
    &:hover {
        border-radius: 3px;
    }
}
.icon-link .icon {
    margin-right: 4px;
}
