@import '../styles/_vars';

.main-header {
    background-color: $backgroundColor;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.sticky {
        transition: all 0.3s ease-in-out;
        top: 0; // Added
        z-index: 1000;
        position: sticky;
        transform: translateY(0);
    }

    height: calc(
        #{$header-height} - 20px
    ); //subtract padding from height used to position overlay and menu
    &.hide {
        transform: translateY(-100%);
    }
    h1 {
        color: #fff;
        margin: 0 auto;
        font-size: 1.5em;

        text-align: center;
        flex: 1;

        abbr {
            text-decoration: none;
            border: none;
            font-weight: bold;
        }

        a {
            color: #fff;
            text-decoration: none;
        }
    }

    .main-nav {
        height: 100%;
        flex: 1;

        .burger-button {
            position: relative;
            padding-top: 6px;

            .icon {
                height: 32px;
                width: 32px;
            }

            .icon-burger {
                display: block;
                cursor: pointer;
                color: #fff;
            }

            .icon-close {
                display: none;
                position: absolute;
                top: 6px;
                left: 0;
                cursor: pointer;
                color: #fff;
                opacity: 0;
            }
        }

        & > ul {
            list-style: none;
            margin: 0;
            padding: 0;
            position: fixed; // Changed to fixed
            top: $header-height; // Align to the top
            left: -390px; // Moved out of the viewport
            width: 390px; // Set width
            background-color: $backgroundColor;
            transition: left 300ms ease-in-out; // Transition for the left property
            height: 100vh; // Set height to 100vh
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            overflow-y: scroll; // Enable scrolling if the content overflows
            z-index: 1000;

            & > li {
                display: block;
                width: 100%;
                animation-duration: 0.3s;
                animation-timing-function: ease-out;
                animation-fill-mode: forwards;
                opacity: 0;
                &.main {
                    a {
                        font-weight: bold;
                        color: #fff;
                        text-decoration: none;
                        padding: 10px 15px;
                        display: block;
                        border: 1px dashed transparent;
                        border-color: transparent;
                        font-size: 20px;
                        &:hover {
                            border-color: $borderColor;
                            transition: border-color 500ms;
                        }

                        &:active {
                            background-color: #ccc;
                            color: $backgroundColor;
                            transition: color, background-color 100ms;
                        }
                    }

                    &:last-child a {
                        border-bottom: 1px dashed $borderColor;
                        border-bottom-color: $borderColor;
                    }
                }

                a {
                    color: #fff;
                    text-decoration: none;
                    font-size: 16px;
                    padding: 10px 15px;
                    display: block;
                    border: 1px dashed transparent;
                    border-color: transparent;
                    // Add transition property to create a smooth animation

                    &:hover {
                        border-color: $borderColor;
                        transition: border-color 500ms;
                    }

                    &:active {
                        background-color: #ccc;
                        color: $backgroundColor;
                        transition: color, background-color 100ms;
                    }
                }
            }
            .social {
                ul {
                    display: flex;
                    align-items: flex-start;
                    margin: 10px 0;
                    padding: 0;

                    list-style: none;
                }
            }
        }
    }
    .flex-start {
        flex: 1;
    }

    .flex-end {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.menu-active {
    .main-header {
        ul {
            left: 0; // Move menu into the viewport

            li {
                animation-name: fadeIn;

                &:nth-child(1) {
                    animation-delay: 0.15s;
                }

                &:nth-child(2) {
                    animation-delay: 0.2s;
                }

                &:nth-child(3) {
                    animation-delay: 0.25s;
                }

                &:nth-child(4) {
                    animation-delay: 0.3s;
                }
            }
        }

        .burger-button .icon-burger {
            transition: opacity 300ms;
            opacity: 0.1;
        }

        .burger-button .icon-close {
            display: block;
            transition: opacity 300ms;
            opacity: 1;
        }
    }
}
