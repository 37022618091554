@keyframes slideDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(-100%);
        opacity: 0;
    }
}

.message-bar {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for better visibility */
    box-sizing: border-box;

    &.active + div {
        margin-top: calc(-1.2em + 4px) !important ;
    }
    .message {
        padding: 0.5em 0.05em;
        height: 1.2em;
        background-color: #f4f4f4; // Neutral background
        border-bottom: 1px solid #e0e0e0; // Slight separation
        font-size: 0.9em; // Smaller font for minimal look
        color: #555; // Dark gray text
        text-align: center;
        vertical-align: middle;
        position: relative;
        animation: slideDown 0.3s ease-out forwards;
        &.closing {
            animation: slideUp 0.3s ease-out forwards;
        }
        &.message-success {
            background-color: green;
            color: #fff;
        }
        &.message-promo {
            background-color: cadetblue;
            color: #fff;
        }
        &.message-cart-update {
            background-color: rgb(199, 126, 0);
            color: #fff;
        }
        &.message-error {
            background-color: #df1b41;
            color: #fff;
            .icon {
                height: 24px;
                width: 24px;
                vertical-align: middle;
                * {
                    stroke: #fff;
                }
            }
        }

        button {
            position: absolute;
            right: 0px;
            top: 8px;
            z-index: 100;
            background: none;
            border: none;
            font-size: 1em;
            color: #888;
            cursor: pointer;
            .icon {
                stroke: #ccc;
                height: 24px;
                width: 24px;
            }
            &:hover {
                color: #555;
            }
        }
    }
}
