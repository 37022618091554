@import '../styles/_vars';

.search-results {
    display: none;
    flex-direction: column;
    align-items: left;
    justify-content: center;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 999;

    width: 100%;

    &.showResults {
        display: flex;
    }

    h2,
    > a:not(.view-more) {
        padding: 10px 35px;
        margin: 0;
        color: #fff;
        border-bottom: 1px dashed $borderColor;
    }

    a {
        &:focus {
            background-color: #fff;
            outline: none;
            h3 {
                color: #000;
            }
        }
        display: flex;
        align-items: center;

        img {
            width: 50px;
            height: auto;
            margin-right: 20px;
        }
        a {
            color: #fff;
        }
        h3 {
            margin: 0;
            font-size: 18px;
            text-decoration: none;
            color: #fff;
            text-decoration: underline;
        }
    }
    div.view-more {
        width: 100%;
        display: flex; // Change to flex to align items
        padding: 0;

        .button {
            display: block;
            &:focus {
                background-color: #fff;
                outline: none;
                color: #000;
            }
            &.search-result-focused {
                background-color: #fff;

                color: #000;
            }
            padding: 35px;
            display: flex; // Change to flex to align items
            align-items: center; // Vertically align items
            color: #fff;
            height: 42px;
            width: 100%;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            text-decoration: none;
            justify-content: space-between; // Align items to the right
            align-items: center; // Vertically align items
            max-width: none;
            .icon {
                height: 30px;
                width: 30px;
            }
        }
    }
}

.main-header {
    .search-results {
        position: absolute;
        top: var(header-height);
        left: calc(50% - 20px);
        transform: translate(-50%, 0);
        background-color: $backgroundColor;
        max-width: 600px;

        div:not(.view-more) {
            padding: 10px 35px;
        }
    }
}

.search-page {
    .search-results {
        position: absolute;
        top: 53px;
        left: 50%;
        transform: translate(-50%, 0);
        background-color: $backgroundColor;
        max-width: 572px;

        div {
            //padding: 10px 35px;
        }
    }
}

@media screen and (max-width: 700px) {
    .search-results.showResults {
        max-width: none;
        display: block;
        left: 0;
        transform: none;
    }
}
