@import '../styles/_vars';

.cart-container {
    position: relative;
    display: flex;
    align-items: center;
    .cart-button {
        height: 40px;
        margin-left: 15px;
        text-align: right;
        width: 42px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    //   display: flex;

    .icon {
        stroke: #fff;
        width: 24px;
        height: 24px;
    }

    .cart-count {
        border-radius: 12px;
        background-color: #00ff33;
        position: absolute;
        top: 2px;
        right: 0px;
        color: #808080;
        font-weight: bold;
        font-size: 11px;
        padding: 3px;
        width: 12px;
        height: 12px;
        text-align: center;
        line-height: 12px;
    }
}

.expanded + .cart-container {
    display: none;
}
