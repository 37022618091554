.hamburger {
    position: relative;
    width: 30px;
    height: 20px;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bar {
    width: 100%;
    height: 2px;
    background: #fff;
    border-radius: 2px;
    /* Apply the subtle glow animation with staggered delays */
    animation: subtleGlow 4.5s infinite ease-in-out;
    transform: translateY(0px) rotate(0deg);
    transition: transform 300ms;
}

/* Stagger the start times so the glow "chases" through the bars */
.bar:nth-child(1) {
    animation-delay: 0.3s;
}
.bar:nth-child(2) {
    animation-delay: 0.55s;
}
.bar:nth-child(3) {
    animation-delay: 0.8s;
}

@keyframes subtleGlow {
    0% {
        transform: scale(1);
        box-shadow: none;
    }
    30% {
        transform: scale(1.1);
        box-shadow: 0 0 5px rgb(255, 255, 255); /* Slight glow color */
    }
    60% {
        transform: scale(1);
        box-shadow: none;
    }
    100% {
        transform: scale(1);
        box-shadow: none;
    }
}
.menu-active .hamburger .bar {
    animation: none;
    transition: transform 500ms;

    /* When open, transform into an 'X' */
    &:nth-child(1) {
        transform: translateY(9px) rotate(45deg);
    }
    &:nth-child(2) {
        opacity: 0;
    }
    &:nth-child(3) {
        transform: translateY(-9px) rotate(-45deg);
    }
}
