@import '../styles/_vars';

.search-box {
    display: flex;
    align-items: center;
    .input-bar {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 10px 0px 10px 0px;
        height: calc(#{$header-height} - 20px);
        width: 100%;

        //  margin-left: 10px;
        .input-label {
            max-width: 570px;
            width: 100%;
            display: flex;
            align-items: center;
            position: relative;
        }

        .label-text {
            position: absolute;
            left: 22px;
            letter-spacing: 0.1em;
            transition: top 600ms ease, font-size 400ms ease;
            transition-delay: top 150ms, font-size 250ms;
            top: initial;

            &.has-text {
                transition: top 200ms ease, font-size 200ms ease;

                font-size: 12px;
                top: 5px;
            }
        }

        .icon-search {
            position: absolute;
            right: 47px;
            stroke: #000;
        }

        .search-input {
            padding: 18px 10px 4px 20px;
            border: 1px solid #ccc;
            border-radius: 1px;
            background-color: #fff;
            width: 100%;
            font-size: 16px;
            &:focus {
                outline: 2px solid #000;
                outline-offset: -2px;
            }
        }

        .icon-close {
            width: 42px;
            height: 42px;
        }
    }
}

.main-header {
    .search-box {
        + .icon-search {
            margin-top: 5px;
            padding: 8px;
            border: 1px dashed transparent;
            border-color: transparent;

            &:hover {
                border-color: $borderColor;
                transition: border-color 300ms;
            }
        }

        .search-overlay {
            position: absolute;
            top: -$header-height;
            left: 0;
            background-color: $backgroundColor;
            transition: top ease-out 100ms;
            width: 100%;
        }

        .input-bar {
            .search-input:focus {
                outline: 2px solid #808080;
            }

            .icon-close {
                width: 42px;
                height: 42px;
            }
        }

        @media screen and (max-width: 700px) {
            .search-input {
                margin-left: 10px;
            }
        }
        &.expanded {
            .search-overlay {
                top: 0;
            }
        }
    }
}

.search-page {
    .search-box {
        > .icon-search {
            display: none;
        }

        .search-overlay {
            position: relative;

            width: 100%;
        }

        .input-bar {
            .icon-close {
                display: none;
            }

            margin-bottom: 40px;
        }

        .input-label {
            max-width: 550px;
        }

        .icon-search {
            right: 10px;
        }

        @media screen and (max-width: 700px) {
            .search-input {
                margin-left: 10px;
            }
        }

        .search-overlay.expanded {
            top: 0;
        }
    }
}
